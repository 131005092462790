import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticStyle:{"color":"#fff"},attrs:{"permanent":"","color":"#272727","id":"core-navigation-drawer","app":"","right":_vm.$vuetify.rtl,"dark":'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-uppercase font-weight-regular"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"align":"center","justify":"center","cols":"12","md":"6"}},[_c('h4',{staticStyle:{"color":"#fff"}},[_vm._v("CMS ")])]),_c(VCol,{staticClass:"text-center"})],1)],1)],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":"https://cdn1.vectorstock.com/i/1000x1000/51/05/male-profile-avatar-with-brown-hair-vector-12055105.jpg"}})]),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$store.state.AdminInfo.name))]),_c(VListItemSubtitle,{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$store.state.AdminInfo.name))])],1)],1),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,staticStyle:{"color":"#fff !important"},attrs:{"link":"","to":item.to}},[_c(VListItemIcon,[_c(VIcon,{staticStyle:{"color":"#fff !important"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }